import React from 'react';
import { Helmet } from "react-helmet";
import MobileNavigation from '../components/MobileNavigation';
import TabletDesktopNavigation from '../components/TabletDesktopNavigation';
import Footer from '../components/Footer';
import MobileSignUp from '../components/styles/MobileSignUp';

const SignUp = () => (
  <div siteTitle='signup'>
    <Helmet>
      <meta charSet="utf-8" />
      <title>About Us</title>
      <link rel="canonical" href="https://best-fit.app/signup" />
    </Helmet>
    <MobileNavigation />
    <TabletDesktopNavigation />
    <MobileSignUp />
    <Footer />
  </div>
);

export default SignUp
