import React from 'react';
import {
  Grid,
  Container,
} from 'semantic-ui-react';
import { SectionHeader } from '../WhySegment';
// import HubspotForm from 'react-hubspot-form';
import MailChimpForm from '../SignupMailChimp';


const MobileSignUp = ({
  text = [ ],
}) => (
    <Container centered style={{ padding: '4em 0em' }} vertical>
      <Grid container centered stackable verticalAlign='middle'>
        <Grid.Row>
          <SectionHeader as='h2' clr='#CD5B49' style={{ marginBottom: '0.5em' }}>
            Sign up
          </SectionHeader>
        </Grid.Row>

        <Grid.Row>
          <MailChimpForm />
          {/* <HubspotForm
            portalId='4359972'
            formId='c8bb6ef6-cbf9-49cf-bfd9-9c55e281a3e3'
            onSubmit={() => console.log('Submit!')}
            onReady={(form) => console.log('Form ready!')}
            loading={<div>Loading...</div>}
          /> */}
        </Grid.Row>
      </Grid>
    </Container>
  );

export default MobileSignUp;